/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *  Step 2: verify email address
 *   Step 3: select plan
 *
 **********/

import React, { useContext } from "react";
import {
  AuthContext,
  Animate,
  Card,
  Form,
  Link,
  Logo,
  Icon,
} from "components/lib";

import { useLocation } from "react-router-dom";

export function Signup(props) {
  const context = useContext(AuthContext);

  const handleSignInCallback = (res) => {
    context.signin(res);
  };

  return (
    <Animate type="pop">
      <div className="splitView">
        <Card loading={false} transparent center>
          <Form
            inputs={{
              name_first: {
                label: "First Name",
                type: "text",
                required: true,
                errorMessage: "Please enter your first name",
              },
              name_last: {
                label: "Last Name",
                type: "text",
                required: true,
                errorMessage: "Please enter your last name",
              },
              email: {
                label: "Email",
                type: "email",
                required: true,
              },
              password: {
                label: "Password",
                type: "password",
                required: true,
                complexPassword: false,
              },
              confirm_password: {
                type: "hidden",
                value: null,
              },
            }}
            url="/api/account"
            method="POST"
            buttonText="Create Account"
            callback={handleSignInCallback}
          />
        </Card>
        <div className="right">
          <div>
            <Logo logo color className="logo" />
            <h2>Create a new account</h2>
            <p>
              We will never share your information.
              <br />
              You can delete your data at any time.
            </p>
            <br />
            Already registered? <Link url="/signin" text="Sign In" />
          </div>
        </div>
      </div>
    </Animate>
  );
}
