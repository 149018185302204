/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  AuthContext,
  Card,
  Button,
  FeaturedItemCard,
  PricePlans,
  FullScreen,
  useOnInView,
  Icon,
} from "components/lib";

import settings from "settings";

import Markdown from "react-markdown";

import compareData from ".//data/compare.data.json";
import featuredData from ".//data/featured.data.json";
import faqData from ".//data/faq.data.json";
import prayerData from ".//data/prayer.data.json";
import "./website.scss";

export function Home(props) {
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  // get the chat id from the settings file
  const chat_id = settings[process.env.REACT_APP_ENV].chat_id;

  //state
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [expandedIndex, setExpandedIndex] = useState(null);
  const [activeCompareIndex, setActiveCompareIndex] = useState(null); // Default to the first option
  const [activeChatIndex, setActiveChatIndex] = useState(4);
  const [activeTopicIndex, setActiveTopicIndex] = useState(1);
  const [activePrayerIndex, setActivePrayerIndex] = useState(0);
  const [infoOverlay, setInfoOverlay] = useState(false);
  const [infoData, setInfoData] = useState([]);
  const [isTyroVisible, setIsTyroVisible] = useState(true);

  // ref for the animate on view
  const [setElements, elements] = useOnInView();

  // get featured content
  const featuredTopic = featuredData.filter(
    (item) => item.type === "workspace"
  );
  const featuredChats = featuredData.filter((item) => item.type === "chatroom");

  useEffect(() => {
    setElements(document.querySelectorAll(".useOnInView"));
  }, []);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, [window.innerWidth]);

  useEffect(() => {
    elements.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("inView");
      }
    });
  }, [elements]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let question;
    if (event.type === "submit") {
      question = event.target.elements.question.value;
    } else if (event.type === "keydown" && event.key === "Enter") {
      question = event.target.value;
    }

    // save question to storage
    localStorage.setItem("question", JSON.stringify(question));

    context.user?.token
      ? navigate("/ai/space/" + chat_id)
      : navigate("/signup");
  };

  // helper function to submit the form when the user presses enter and new line with shift+enter
  function enterSubmit(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit(event);
    }
  }

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  function showAi(data, type) {
    data.type = type;
    setInfoData(data);
    setInfoOverlay(true);
  }

  // Ref for the scrollable container
  const scrollContainerCompareRef = useRef(null);
  const scrollContainerTopicRef = useRef(null);
  const scrollContainerChatRef = useRef(null);
  const scrollContainerPrayerRef = useRef(null);

  const scrollTo = (ref, direction) => {
    if (ref.current) {
      const itemWidth = ref.current.children[0].offsetWidth;
      const currentScroll = ref.current.scrollLeft;
      let newScrollPosition;

      // Determine the new scroll position based on the direction
      if (direction === "forward") {
        newScrollPosition = currentScroll + itemWidth;
      } else if (direction === "backward") {
        newScrollPosition = currentScroll - itemWidth;
      } else {
        console.error("Invalid direction. Use 'forward' or 'backward'.");
        return; // Exit the function if the direction is invalid
      }

      ref.current.scrollTo({
        left: newScrollPosition,
        behavior: "auto", // Immediate snapping
      });
    }
  };

  useEffect(() => {
    scrollTo(scrollContainerTopicRef, "forward");

    scrollTo(scrollContainerCompareRef, "forward");

    scrollTo(scrollContainerPrayerRef, "forward");
  }, []); // Empty dependency array means this effect runs only once after the initial render

  // Toggle function that works only if isMobile is true
  const toggleVisibility = () => {
    if (isMobile) {
      setIsTyroVisible(!isTyroVisible);
    }
  };
  const [inputValue, setInputValue] = useState("");
  const [placeholderText, setPlaceholderText] = useState("");
  const phraseIntervalRef = useRef(null); // Use useRef to store the interval ID
  const placeholderTextRef = useRef(""); // Use useRef to store the current placeholder text

  const phrases = [
    "Enter your question here...",
    "What can we help you with?",
    "Type your query...",
    "Ask us anything...",
    "Need assistance? Start typing...",
  ];
  let phraseIndex = 0;
  let charIndex = 0;

  useEffect(() => {
    const typePhrase = () => {
      if (charIndex < phrases[phraseIndex].length) {
        placeholderTextRef.current += phrases[phraseIndex].charAt(charIndex);
        setPlaceholderText(placeholderTextRef.current);
        charIndex++;
      } else {
        clearInterval(phraseIntervalRef.current);
        setTimeout(() => {
          charIndex = 0;
          phraseIndex = (phraseIndex + 1) % phrases.length;
          placeholderTextRef.current = "";
          setPlaceholderText("");
          phraseIntervalRef.current = setInterval(typePhrase, 100);
        }, 2000); // Wait for 2 seconds before typing the next phrase
      }
    };

    phraseIntervalRef.current = setInterval(typePhrase, 100);

    return () => clearInterval(phraseIntervalRef.current);
  }, []);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (e.target.value !== "") {
      clearInterval(phraseIntervalRef.current); // Stop typing phrases when user starts typing
    }
  };
  return (
    <div className="homeContent">
      <Card className="intro " transparent>
        <div className="question">
          <h1 className="gold">Ask Tyro anything</h1>

          <p>Ai from a Catholic world view.</p>

          <form
            id="questionForm"
            method="post"
            onSubmit={handleSubmit}
            onKeyDown={enterSubmit}
          >
            <div className="questionWrapper">
              <textarea
                id="question"
                type="text"
                name="question"
                value={inputValue}
                onChange={handleChange}
                placeholder={inputValue ? "" : placeholderText}
                required
              ></textarea>

              <button type="submit">
                <Icon image="arrow-up" />
              </button>
            </div>
          </form>
        </div>
      </Card>

      <Card className="topics" extraPadding noRadius>
        <div className="sectionHeading">
          <h1>Deepen your knowledge on important topics</h1>
          <p>Tyro Ai puts even the most complex topics within reach.</p>
        </div>
        <div className="topicGraphics" ref={scrollContainerTopicRef}>
          {featuredTopic.map((topic, index) => {
            // Create a modified copy of topic based on isMobile condition
            const modifiedTopic = {
              ...topic,
              description: topic.question,
            };

            return (
              <FeaturedItemCard
                data={modifiedTopic} // Pass the modified topic here
                aiType="workspace"
                key={index}
                onClick={() => {
                  setActiveTopicIndex(index);
                }}
                className={`${
                  index === activeTopicIndex && !isMobile ? "selected" : ""
                } useOnInView`}
              ></FeaturedItemCard>
            );
          })}
        </div>
        <div className="tryTyro">
          <Button
            icon="arrow-right-circle"
            text="Try Tyro"
            action={() => navigate("/signup")}
            outline
            color="brown"
          />
        </div>
      </Card>

      <Card className="compare" center extraPadding transparent>
        <div className="sectionHeading">
          <h1>Built on Catholic teaching</h1>
          <p>
            See the difference between Tyro and the leading Ai on moral issues.
          </p>
        </div>

        <div className="compareOptions" ref={scrollContainerCompareRef}>
          {compareData.map((compare, index) => (
            <div
              key={index}
              className={`compareCard ${
                index === activeCompareIndex ? "active" : ""
              }`}
              onClick={() => setActiveCompareIndex(index)}
            >
              <h3>Q: {compare.title}</h3>
              <p>{compare.question}</p>
            </div>
          ))}
        </div>

        <div
          className={`comparisons ${
            activeCompareIndex === null || activeCompareIndex === undefined
              ? "closed"
              : ""
          }`}
        >
          {isMobile ? (
            isTyroVisible ? (
              <div className="left markdown">
                <div className="buttonContainer">
                  <button className="active">Tyro</button>
                  <button className="notActive" onClick={toggleVisibility}>
                    Leading Ai
                  </button>
                </div>
                <h3>Q: {compareData[activeCompareIndex]?.question}</h3>
                <hr />
                <Markdown>{compareData[activeCompareIndex]?.tyro}</Markdown>
              </div>
            ) : (
              <div className="right markdown">
                <div className="buttonContainer">
                  <button className="notActive" onClick={toggleVisibility}>
                    Tyro
                  </button>
                  <button className="active">Leading Ai</button>
                </div>

                <h3>Q: {compareData[activeCompareIndex]?.question}</h3>
                <hr />
                <Markdown>{compareData[activeCompareIndex]?.chat}</Markdown>
              </div>
            )
          ) : (
            <>
              <div className="left markdown">
                <cite>Tyro</cite>
                <h3>Q: {compareData[activeCompareIndex]?.question}</h3>
                <hr />
                <Markdown>{compareData[activeCompareIndex]?.tyro}</Markdown>
              </div>
              <div className="right markdown">
                <cite>Leading Ai</cite>
                <h3>Q: {compareData[activeCompareIndex]?.question}</h3>
                <hr />
                <Markdown>{compareData[activeCompareIndex]?.chat}</Markdown>
              </div>
            </>
          )}
        </div>
      </Card>

      <Card className="chat" extraPadding noRadius>
        <div className="sectionHeading">
          <h1>Chat with Historical figures from the Catholic Church</h1>
          <p>
            Enjoy life like conversations built on the work and voice of
            prominent Catholic figures.
          </p>
        </div>

        {/* mobile version */}

        <div className="chatContainerMobile" ref={scrollContainerChatRef}>
          {featuredChats.map((chat, index) => (
            <div className={"chatHighlight useOnInView"} key={index}>
              <img
                src={featuredChats[index].thumbnail_url}
                alt={featuredChats[index].title}
                className="chatImage"
              />
              <div className="chatQA">
                <h2>{featuredChats[index].title}</h2>
                <h3 className="question">{featuredChats[index].question}</h3>
                <Button
                  icon="arrow-right-circle"
                  text="Try Tyro"
                  action={() => navigate("/signup")}
                  outline
                  color="brown"
                />
              </div>
            </div>
          ))}
        </div>
        <div className="scrollButtonContainer">
          <Button
            icon="arrow-left-circle"
            iconSize="44"
            action={() => scrollTo(scrollContainerChatRef, "backward")}
          />
          <Button
            icon="arrow-right-circle"
            iconSize="44"
            action={() => scrollTo(scrollContainerChatRef, "forward")}
          />
        </div>
      </Card>

      <Card className="faq" extraPadding transparent>
        <div className="sectionHeading">
          <h1>Questions and Answers</h1>
        </div>
        <div className="faqContainer">
          {faqData.map((item, index) => (
            <div key={index} className="faq-item">
              <div
                className={`faq-question ${
                  expandedIndex === index ? "open" : ""
                }`}
                onClick={() => toggleExpand(index)}
              >
                <p>{item.question}</p>
              </div>
              {expandedIndex === index && (
                <p className="faq-answer">{item.answer}</p>
              )}
            </div>
          ))}
        </div>
      </Card>
      {infoOverlay && (
        <FullScreen
          large
          className="infoOverlay"
          closeFunction={() => setInfoOverlay(false)}
          closeBtn
        >
          <div className="top fullScreenHeader">
            {infoData.thumbnail_url && (
              <img
                src={infoData.thumbnail_url}
                alt={infoData.title}
                className={infoData.type}
              />
            )}
            <div>
              <h1>
                <span className="capitalize"> {infoData.title}</span>
              </h1>
            </div>
          </div>
          <div className="fullScreenContent">
            <div className="markdown">
              <p className="question">{`Q: ${infoData.question}`}</p>
              <Markdown>{infoData.answer}</Markdown>
            </div>
          </div>
          <cite>Responses are generated by Ai and may contain errors.</cite>
        </FullScreen>
      )}
      <script src="https://secureaddisplay.com/ctp/js/lp/"></script>
    </div>
  );
}
