/***
 *
 *   CONTACT FORM
 *   Will send an email notification to you
 *
 **********/

import React from "react";
import { Animate, Card, ContactForm, Logo } from "components/lib";

export function Contact(props) {
  return (
    <Animate type="pop">
      <div className="splitView">
        <Card className="contactCard" transparent center>
          <ContactForm />
        </Card>
        <div className="right">
          <div>
            <Logo logo color className="logo" />

            <h2>Contact Us</h2>
            <p>
              If you have any questions or need help, please fill out the form
              and we will get back to you as soon as possible
            </p>
          </div>
        </div>
      </div>
    </Animate>
  );
}
