import React, { useContext } from "react";
import {
  AuthContext,
  Animate,
  Logo,
  Card,
  Form,
  useNavigate,
  useLocation,
} from "components/lib";

export function SigninOTP(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(AuthContext);

  const token = location?.search?.substring(7);
  if (!token) navigate("/signin");

  return (
    <Animate type="pop">
      <div className="splitView">
        <Card transparent center>
          <Form
            method="post"
            url="/api/auth/otp"
            inputs={{
              code: {
                type: "text",
                label: "Verification code",
                required: true,
              },
              jwt: {
                type: "hidden",
                value: token,
              },
            }}
            buttonText="Complete Sign In"
            callback={context.signin}
          />

          <div className="mt-4">
            Lost access to your authenticator app? Please enter your backup code
            above.
          </div>
        </Card>

        <div className="right">
          <div>
            <Logo logo color className="logo" />

            <h2>Sign in</h2>
            <p>
              Enter the verification code from your authenticator app to sign
              in.
            </p>
          </div>
        </div>
      </div>
    </Animate>
  );
}
