import { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useAPI,
  ChatMessage,
  OrganizationCard,
  ViewContext,
  Icon,
  LoaderChat,
  Button,
  Table,
  FullScreen,
  DiscoverContainer,
  PrayerButton,
  OrganizationTable,
} from "components/lib";
import Axios from "axios";
import settings from "settings";
import Style from "./space.view.scss";
import { space } from "postcss/lib/list";

// array to hold the conversation
var workspace = [];

//save the history id so we can update the history record
let currentHistoryId = null;

// the prompt object stores all the info about the current prompt
var spaceConfig = [];

export function Space(props) {
  // allows us to navigate to a different page
  const navigate = useNavigate();

  // allows us to get props/functions from parent
  const viewContext = useContext(ViewContext);

  //allows us to get URL params - in this case the prompt id
  const params = useParams();

  //allows up to reference UI elsments
  const scrollToRef = useRef(null);
  const loaderRef = useRef(null);
  const scrollableContainerRef = useRef(null);
  const leftColRef = useRef(null);
  const headerRef = useRef(null);

  //set state for the active session
  const [activeSession, setActiveSession] = useState({});

  //set state for the text input
  const [messageTextInput, setMessageTextInput] = useState([""]);

  //create a state for the chat so we can update the UI
  const [chatContent, setchatContent] = useState([]);

  // temporary state for form submission status
  const [submitting, setSubmitting] = useState(false);
  const [notification, setNotification] = useState("");

  // control UI elements
  const [resumeOverlay, setResumeOverlay] = useState(false);
  const [showStarters, setShowStarters] = useState(false);
  const [isStarterExpanded, setIsStarterExpanded] = useState(false);
  const [infoOverlay, setInfoOverlay] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // get the sessions for the user in the space
  // we just need the session title and ID - sorted by newest first
  const [sessions, setSessions] = useState([]);

  // get the space data ( data: id, title, description, greeting, assistant_id, thumbnail_url, type, sessions: [] starters: [])
  const spaceConfig =
    useAPI(`/api/ai/spaceconfig/${params.space_id}`).data || [];

  useEffect(() => {
    async function fetchData() {
      if (!spaceConfig?.loading && spaceConfig?.id != null) {
        // We need to check the URL for
        // a. question
        // b. starter_id
        // c. session_id
        // and if so run the process for laoding one of those
        // if not we will check if there was a
        // d. previous session
        // if so we should ask the user if they want to start a new session or load the previous
        // if new session we then add the starters to the UI
        // e. otherwise just start a new session
        setchatContent([]);

        const question = JSON.parse(localStorage.getItem("question"));

        if (question) {
          //a. question
          loadQuestionUI(question);
          localStorage.removeItem("question"); // Remove the item from localStorage
        } else if (params.starter_id) {
          //b. starter_id
          loadStarterUI(params.starter_id);
        } else if (params.session_id) {
          //c. session_id
          getAndLoadSession(params.session_id);
        } else if (spaceConfig.sessions.length > 0) {
          //d. previous session
          //ask the user if they want to load a previous session
          // or start a new session
          setResumeOverlay(true);
        } else {
          // e. this is the first time the user has used this
          // just show the title and greeting
          startNewSession();
        }

        // update the sessions with a friendly date and add to the state
        setSessions(await updateSessionsWIthFriendlyDate(spaceConfig.sessions));
      }
    }
    fetchData();
  }, [spaceConfig, params.starter_id, params.session_id]);

  useEffect(() => {
    // set the url to the current session
    if (activeSession?.id != null && !props.starter_id) {
      navigate(`/ai/space/${params.space_id}/session/${activeSession.id}`);
      // if no session then set the url to space id only
    }
  }, [activeSession]);

  // the gets all of the session data
  async function getAndLoadSession(sessionID) {
    const response = await Axios.get(`/api/ai/session/${sessionID}`);

    // update the active session
    setActiveSession({
      id: response.data.session.id,
      title: response.data.session.title,
    });

    resetWorkspace(response.data.session.thread);
    setShowStarters(false);
    scrollTo();
  }

  function resetWorkspace(messages) {
    if (messages && messages.length > 0) {
      updateWorkspace(spaceConfig.greeting, "assistant");
      setchatContent((prevContent) => [...prevContent, ...messages]);
    } else {
      setchatContent([]);
      updateWorkspace(spaceConfig.greeting, "assistant");
    }
  }

  async function loadQuestionUI(question) {
    // add the description to the text area
    // add the title to the space
    updateWorkspace(spaceConfig.greeting, "assistant");
    setMessageTextInput(question);
    setActiveSession((prevState) => ({
      ...prevState,
      title: "New Question...",
    }));

    handleSubmit(null, question);
  }

  async function loadStarterUI(starter_id) {
    // get the starter data
    const starter = spaceConfig.starters?.data?.find(
      (starter) => starter.id === starter_id
    );

    // add the description to the text area
    // add the title to the space
    updateWorkspace(spaceConfig.greeting, "assistant");
    setMessageTextInput(starter?.description);
    setActiveSession((prevState) => ({
      ...prevState,
      title: starter?.title,
    }));
  }

  // function to update the chat - keeping track of the whole chat
  function updateWorkspace(text, role) {
    var message = { role: role, content: text };
    setchatContent((prevContent) => [...prevContent, message]);
  }

  // Attached to Form  - add to the question input to update the state
  const handleChange = (event) => {
    setMessageTextInput(event.target.value);
  };

  // Attached to Form - submit the question
  function handleSubmit(event, message) {
    let messageText = message || messageTextInput;

    //  management
    if (submitting) return; // prevent multiple submissions
    if (event) event.preventDefault(); // Prevent the browser from reloading the page

    // set the data for the post
    let dataTemp = {};
    dataTemp.message = { role: "user", content: messageText };
    dataTemp.session_id = activeSession.id;
    dataTemp.space_id = params.space_id;

    // update the workspace
    setMessageTextInput(null); // clear the message text area
    updateWorkspace(messageText, "user"); // add the user message
    scrollTo(); // scroll to the bottom of the workspace so it is visible for the user

    // post the message
    postMessage("/api/ai/space/message", dataTemp);
    setSubmitting(true);
    showLoading(true);
    setShowStarters(false);
  }

  // post the message to the server and get the response
  async function postMessage(postUrl, dataTemp) {
    await Axios({
      method: "post",
      url: postUrl,
      data: dataTemp,
    })
      .then((response) => {
        // update the workspace with the response
        updateWorkspace(
          response.data.message.content,
          response.data.message.role
        );
        setActiveSession((prevState) => ({
          title: response.data.summary,
          id: response.data.session_id,
        }));
        //make sure the message did not get flagged
        //if (response?.data?.flagged !== true) postCreateHistory(dataTemp.prompt);
      })
      .catch((error) => {
        newNotification("Error", error);
        viewContext.handleError(error);
      });
    showLoading(false);
    setSubmitting(false);
    scrollTo();
  }

  function continueSession() {
    setResumeOverlay(false);
    getAndLoadSession(spaceConfig.sessions[0].id);
  }

  function startNewSession() {
    setResumeOverlay(false);
    setInfoOverlay(false);
    setActiveSession({
      title: "New Conversation",
    });
    resetWorkspace([]);
    setShowStarters(true);
  }

  //used to create a new session (on server response)
  async function createNewSession(session) {
    setActiveSession(session);
    resetWorkspace([]);
    // push session the sessions list
    setSessions((prevState) => [session, ...prevState]);
  }

  function deleteSession() {}
  // helper function to update the sessions with a friendly date
  async function updateSessionsWIthFriendlyDate(org_sessions) {
    // Map over the sessions and convert updated_at to a friendly date
    const friendlySessions = org_sessions.map((session) => {
      let date = new Date(session.updated_at);
      let friendlyDate = date.toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });

      // Replace updated_at with the friendly date
      return { ...session, updated_at: friendlyDate };
    });
    return friendlySessions;
  }

  // helper function to create a new notification
  function newNotification(type, message) {
    setNotification({ type, message });
  }

  // helper function to submit the form when the user presses enter and new line with shift+enter
  function enterSubmit(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit(event);
    }
  }

  // UI function - after the answer loads we need to scroll to the bottom so the entire answer is visible
  function scrollTo() {
    setTimeout(() => {
      const scrollableContainer = scrollableContainerRef.current;
      const elementToScrollTo = scrollToRef.current;

      if (scrollableContainer && elementToScrollTo) {
        const topPosition =
          elementToScrollTo.offsetTop - scrollableContainer.offsetTop;
        scrollableContainer.scroll({
          top: topPosition,
          behavior: "smooth",
        });
      }
    }, 200); // 200ms delay before scrolling
  }

  //helper function to show the loading animation
  function showLoading(boolVal) {
    const displayVal = boolVal ? "visible" : "hidden";
    if (loaderRef.current === null) return;
    loaderRef.current.style.visibility = displayVal;
  }

  //helper function to toggle the starters
  const toggleStarterExpanded = () => {
    setIsStarterExpanded(!isStarterExpanded);
  };

  return (
    <div className="spaceViewContainer">
      <div className="middle">
        <section className="left-col ">
          <div className="top">
            <img
              src={spaceConfig.thumbnail_url}
              className={spaceConfig.type}
              alt={spaceConfig.title}
              onClick={setInfoOverlay}
            />
            <div onClick={setInfoOverlay} className="middle">
              <span className="capitalize">
                <b>
                  {spaceConfig.title}
                  &nbsp;:&nbsp;
                </b>
              </span>
              <span key={activeSession?.title} className="sessionTitle">
                {" "}
                {activeSession?.title}
              </span>
            </div>
            <Button
              icon="info"
              iconSize="21"
              className={"infoIcon"}
              action={() => setInfoOverlay(true)}
            />
          </div>
          <div className="chatArea" ref={scrollableContainerRef}>
            <div id="msg">
              {chatContent?.map((answer, index) => (
                <div
                  key={index}
                  ref={index === chatContent.length - 1 ? scrollToRef : null}
                >
                  <ChatMessage
                    row={index % 2 === 0 ? "altA" : "altB"}
                    text={answer.content}
                    role={answer.role === "assistant" ? "ai" : "user"}
                    className={answer.role === "assistant" ? "ai" : "user"}
                  />
                </div>
              ))}

              <div className="loaderSmall" ref={loaderRef}>
                <LoaderChat />
              </div>
            </div>
            {showStarters && spaceConfig?.starters && (
              <DiscoverContainer transparent className="starters">
                <div
                  className={`grid-4 ${
                    isStarterExpanded ? "expanded" : "collapsed"
                  }`}
                >
                  {spaceConfig?.starters?.data?.length > 2 &&
                    (isStarterExpanded
                      ? spaceConfig?.starters?.data
                      : spaceConfig?.starters?.data.slice(0, isMobile ? 2 : 4)
                    ).map((starter) => (
                      <PrayerButton
                        key={starter.id}
                        title={starter.title}
                        description={starter.description}
                        starter_id={starter.id}
                        space_id={spaceConfig.id}
                        dark
                      />
                    ))}
                </div>
                {spaceConfig?.starters?.data?.length > 4 && (
                  <Button
                    action={toggleStarterExpanded}
                    icon={isStarterExpanded ? "chevron-up" : "chevron-down"}
                    className={"expandBtn"}
                  />
                )}
              </DiscoverContainer>
            )}
          </div>
          <div className="bottom">
            <form id="questionForm" method="post" onSubmit={handleSubmit}>
              <div className="questionWrapper">
                <textarea
                  id="question"
                  type="text"
                  name="question"
                  required
                  onChange={handleChange}
                  onKeyDown={enterSubmit}
                  value={messageTextInput || ""}
                ></textarea>
                <button type="submit">
                  <Icon image="arrow-up" />
                </button>
              </div>
            </form>
            <cite>Responses are generated by Ai and may contain errors.</cite>
          </div>
        </section>
        {!isMobile && (
          <section className="conversations right-col">
            <h4 className="top">Conversation History</h4>
            <div className="middle">
              <OrganizationTable
                history
                title="sessions"
                data={sessions}
                show={["title"]}
                loading={spaceConfig.loading}
                link={{
                  url: `/ai/space/${params.space_id}/session/`,
                  id: "id",
                }}
              />
            </div>
            <Button
              text="New Conversation"
              className="bottom"
              action={startNewSession}
            />
          </section>
        )}
      </div>

      {resumeOverlay && (
        <FullScreen textCenter className="resumeOverlay">
          <img
            src={spaceConfig.thumbnail_url}
            className={spaceConfig.type}
            alt={spaceConfig.titl}
          />
          <h2>
            Hi, Welcome back to
            <br />
            <strong>{spaceConfig.title}</strong>
          </h2>
          <p>
            Would you like to start a new conversation or continue where you
            left off?
          </p>
          <div>
            <Button text="Start New Conversation" action={startNewSession} />

            <Button
              text="Continue Last Conversation"
              action={continueSession}
            />
          </div>
        </FullScreen>
      )}

      {infoOverlay && (
        <FullScreen
          large
          className="infoOverlay"
          closeFunction={() => setInfoOverlay(false)}
          closeBtn
        >
          {isMobile && (
            <img
              src={spaceConfig.thumbnail_url}
              className={spaceConfig.type}
              alt={spaceConfig.titl}
            />
          )}
          <div className="top header">
            {!isMobile && (
              <img
                src={spaceConfig.thumbnail_url}
                className={spaceConfig.type}
                alt={spaceConfig.titl}
              />
            )}
            <div>
              <h1>
                <span className="capitalize"> {spaceConfig.title}</span>
              </h1>
              <p>{spaceConfig.description}</p>
            </div>
          </div>

          {spaceConfig?.files?.length > 0 && (
            <div className="files">
              <h4>Knowledge Sources</h4>
              {spaceConfig.files.map((file) => (
                <div key={file.id} className="file">
                  <Icon image="file" title={file.name} className="fileIcon" />
                  <p>{file.name}</p>
                </div>
              ))}
            </div>
          )}

          {isMobile && (
            <section className="conversations">
              <h4 className="top">Conversation History</h4>
              <div className="middle">
                <OrganizationTable
                  history
                  title="sessions"
                  data={sessions}
                  show={["title"]}
                  loading={spaceConfig.loading}
                  link={{
                    url: `/ai/space/${params.space_id}/session/`,
                    id: "id",
                  }}
                />
              </div>
              <Button text="New Conversation" action={startNewSession} />
            </section>
          )}
        </FullScreen>
      )}
    </div>
  );
}
