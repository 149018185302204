/***
 *
 *   FORGOT PASSWORD
 *   Trigger a password reset process
 *
 **********/

import React from "react";
import { Animate, Logo, Form, Card } from "components/lib";

export function ForgotPassword(props) {
  return (
    <Animate type="pop">
      <div className="splitView">
        <Card center transparent title="Reset Your Password">
          <Form
            inputs={{
              email: {
                label: "Email",
                type: "email",
                required: true,
              },
            }}
            url="/api/auth/password/reset/request"
            method="POST"
            buttonText="Reset Password"
          />
        </Card>
        <div className="right">
          <div>
            <Logo logo color className="logo" />

            <h2>Don't worry, it happens</h2>
            <p>
              Enter your email address and we'll send you instructions to reset
              your password.
            </p>
          </div>
        </div>
      </div>
    </Animate>
  );
}
