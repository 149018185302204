/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *   view: view filename
 *
 *
 **********/

import { Fragment, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext, AppNav, Blur } from "components/lib";
import Style from "./app.module.scss";
import settings from "settings";

export function AppLayout(props) {
  // context & style
  const context = useContext(AuthContext);

  // we need to grab the pathname to get the current page
  const location = useLocation();

  // get the chat id from the settings file
  const chat_id = settings[process.env.REACT_APP_ENV].chat_id;

  return (
    <Fragment>
      <AppNav
        items={[
          { label: "Chat", icon: "loader", link: "/ai/space/" + chat_id },
          { label: "People", icon: "user", link: "/ai/list/people" },

          { label: "Topics", icon: "coffee", link: "/ai/list/topics" },

          {
            label: "Create",
            icon: "zap",
            permission: "supertyro",
            link: "/ai/create",
          },
          { label: "Account", icon: "at-sign", link: "/account" },
        ]}
      />
      <main className={`${Style.app} ${props.title} wide-margin`}>
        {<props.children {...props.data} />}
      </main>
    </Fragment>
  );
}
