/***
 *
 *   SIGN UP STEP 2
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: select plan
 *
 **********/

import React, { useContext } from "react";
import {
  Animate,
  AuthContext,
  Row,
  Card,
  Logo,
  PaymentForm,
  usePlans,
  Link,
  useNavigate,
  Event,
} from "components/lib";

export function SignupPlan(props) {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const plans = usePlans();
  const plan =
    window.location.hash.substring(1) || localStorage.getItem("selectedPlanId");

  console.log("plans", plans.data);
  function useFreePlan() {
    context.update({
      // plan: res.data.plan,
      // subscription: res.data.subscription,
    });
  }

  if (!plans.data) return false;

  function trackSubscription() {
    if (window.fbq) {
      window.fbq("track", "Subscribe");
    } else {
      console.warn("Facebook Pixel not initialized");
    }
  }

  return (
    <Animate type="pop">
      <div className="splitView">
        <Card loading={false} transparent center>
          <PaymentForm
            inputs={{
              plan: {
                label: "Plan",
                type: "select",
                options: plans.data.list,
                default: plan,
                required: true,
              },
              token: {
                label: "Credit Card",
                type: "creditcard",
                required: true,
              },
            }}
            url="/api/account/plan"
            method="POST"
            buttonText="Save Plan"
            callback={(res) => {
              // save the plan to context, then redirect
              Event("selected_plan", { plan: res.data.plan });
              context.update({
                plan: res.data.plan,
                subscription: res.data.subscription,
              });
              // navigate(res.data.onboarded ? "/discover" : "/welcome");

              trackSubscription();
              navigate("/discover");
            }}
          />
        </Card>
        <div className="right">
          <div>
            <Logo logo color className="logo" />

            <h2>Choose Your Plan</h2>
            <p>
              Select a plan to get started. You can change your plan at any
              time.
            </p>
          </div>
        </div>
      </div>
    </Animate>
  );
}
