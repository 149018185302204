/***
 *
 *   SOCIAL SIGN IN
 *   After social authentication has been completed on the server
 *   the user is redirected back to this view - which obtains
 *   the token and completes the signin flow as normal
 *
 **********/

import React, { useContext, useEffect, useRef } from "react";
import axios from "axios";
import {
  Animate,
  AuthContext,
  ViewContext,
  Card,
  Logo,
  Message,
  useNavigate,
  useLocation,
} from "components/lib";

export function SocialSignin(props) {
  // wrap in useRef to prevent triggering useEffect multiple times
  const authContext = useRef(useContext(AuthContext));
  const viewContext = useRef(useContext(ViewContext));
  const navigate = useNavigate();
  const location = useLocation();

  const qs = location.search;
  const provider = qs.substring(qs.indexOf("?provider") + 10, qs.indexOf("&"));
  const token = qs.substring(qs.indexOf("&token") + 7);

  useEffect(() => {
    async function fetchToken() {
      try {
        const res = await axios({
          method: "post",
          url: "/api/auth",
          data: { token: token },
        });
        res.data["2fa_required"]
          ? navigate(`/signin/otp?token=${res.data.token}`)
          : authContext.current.signin(res);
      } catch (err) {
        viewContext.current.handleError(err);
      }
    }

    if (token) fetchToken();
  }, [token, viewContext, authContext, navigate]);

  return (
    <Animate type="pop">
      <div className="splitView">
        <Card transparent loading={token} center>
          {!token && (
            <div>
              <Message
                type="error"
                title={`Something wen't wrong`}
                text="Invalid URL, please try in again"
                buttonText="Sign In"
                buttonLink="/signin"
              />
            </div>
          )}
        </Card>
        <div className="right">
          <div>
            <Logo logo color className="logo" />

            <h2>Magic Link</h2>
            <p>
              {`Signing in with ${
                provider.charAt(0).toUpperCase() + provider.slice(1)
              }`}
            </p>
          </div>
        </div>
      </div>
    </Animate>
  );
}
